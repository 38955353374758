<template>
  <v-container class="send-mail">
    <v-card elevation="6" max-width="850" class="mx-auto mt-5 mb-5" >
      <v-card-title class="font-weight-medium">
        新規作成
        <v-icon class="ml-2">
          mdi-email-outline
        </v-icon>
          <v-btn class="to-send-list" outlined rounded color="cyan darken-4" @click="toSentMails">送信済みメール一覧へ</v-btn>

      </v-card-title>
  <v-form
    ref="form"
    lazy-validation
  >

      <v-card-text>
        <div v-if="errors.length" class="errorMessage">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>

        <v-text-field
          v-for="(item, i) in form"
          v-model="$data[form[i].id]"
          clearable
          :key="i"
          :label="item.label"
          :disabled="item.disabled"
          :prepend-icon="item.icon"
          :rules="item.rule"
          :id="item.id"
          class="send-mail-input"
          >

          <template v-slot:append-outer>
            <v-btn rounded v-if="item.hasButton" class="add" v-on:click="addForm">追加</v-btn>
            <v-btn rounded v-if="item.hasButton2" class="add" v-on:click="addForm2">追加</v-btn>
            <v-btn rounded v-if="item.hasButton3" class="add" v-on:click="addForm3">追加</v-btn>
            <v-btn rounded color="grey" v-if="item.hasButton4" class="white--text" v-on:click="toggleForm1">×</v-btn>
            <v-btn rounded color="grey" v-if="item.hasButton5" class="white--text" v-on:click="toggleForm2">×</v-btn>
            <v-btn rounded color="grey" v-if="item.hasButton6" class="white--text" v-on:click="toggleForm3">×</v-btn>
          </template>

        </v-text-field>
        <!-- 添付ファイル -->
        <div class="mb-1">
          <span id="attachTitle">
            添付ファイル
          </span>
          <v-icon id="attachIcon">
            mdi-paperclip
          </v-icon>
          <span id="requireFile">
           必須項目です。
          </span>
        </div>
        <file-pond
          name="uploadFiles"
          label-idle="クリックしてファイルを選択(添付ファイルは5つまで)"
          allowMultiple="true"
          max-files="5"
          instantUpload="false"
          ref="pond"
          v-on:updatefiles="handleFilePondUpdateFile"
          v-bind:required="true"
        />
        <!-- 本文 -->
        <v-textarea
          autocomplete="email"
          label="本文"
          v-model="content"
          id="content"
          name="content"
          rows="12"
          clearable
          dense
          counter
          auto-grow
        />
        <v-btn
          rounded
          :dark = "!isPush"
          :disabled="isPush"
          color="cyan darken-1"
          @click="doSend"
        >
          送信
          <v-icon class="ml-2" small>
            mdi-send
          </v-icon>
        </v-btn>
      </v-card-text>
 </v-form>
    </v-card>
<!--      <div :class="progressClass">-->
<!--          <v-progress-circular-->
<!--            :size="80"-->
<!--            :width="5"-->
<!--            color="primary"-->
<!--            indeterminate-->
<!--          >送信中</v-progress-circular>-->
<!--      </div>-->
      <div :class="progressClass" >
        <!-- プログレスバー（HTML5） -->
        <progress class="progress" ref="progress" value="0" max="100"></progress><br>
        <span class="text-muted">{{ progress_message }}</span>
      </div>
    </v-container>
</template>

<style >
#requireFile {
  visibility: hidden;
  color: #E53935;
}

.redcolor{
	color:#E53935 !important;
}
.send-mail .v-card__title{
  padding-bottom: 0;
  font-size: 1rem;
}
.send-mail{
  margin-top: 0;
  padding-top:0;
}
.send-mail .v-card__text{
  padding-top:0;
}

.send-mail .v-progress-circular {
  margin: 1rem;
}
.send-mail .send-mail-input{
  padding:0;
  margin:0;
}

.send-mail .send-mail-input .v-label--active{
  /*display: none;*/
  transform: none!important;
}
.send-mail .v-text-field{
  padding-top:0;
  margin-top:0;
}
.send-mail .text-progress-center{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 30%;
  text-align: center;
  background: rgba(255,255,255,0.7);
}
.send-mail .progress{
  height: 35px;
}
.send-mail-input input{
  margin-left:3.5rem;
}
.send-mail .display-none{
  display:none;
}
.send-mail .to-send-list{
  right: 16px;
  display: inline-block;
  position: absolute;
}
</style>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
// import * as API from '../../services/fileupload-api';
import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const FilePond = vueFilePond()

export default {
  data() {
    return {
      progress: false,
      errors: [],
      progress_message: '',
      close: "",
      subtitle: '',
      receiver_list: '',
      to_list: '',
      cc_list: '',
      bcc_list: '',
      title: '',
      content: '',
      uploadFiles: [],
      isPush : false,
      cc_num: 0,
      bcc_num: 0,
      to_num: 0,
      form: [
        {
          label: '承認者',
          disabled: true,
          icon: 'mdi-account',
          id: 'receiver_list',
          rule: [
            value => (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || !value) || '正しく入力してください。'
          ]
        },
        {
          label: '宛先',
          disabled: false,
          icon: 'mdi-account',
          id: 'to_list',
          rule: [
            value => !!value || '必須項目です。',
            value => /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || '正しく入力してください。'
          ],
          // 追加ボタンを持つかどうか
          hasButton3: true,
        },
        {
          label: 'CC',
          disabled: false,
          icon: 'mdi-account-multiple',
          id: 'cc_list',
          rule: [
            value => (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || !value) || '正しく入力してください。'
          ],
          // 追加ボタンを持つかどうか
          hasButton: true
        },
        {
          label: 'BCC',
          disabled: false,
          icon: 'mdi-account-multiple',
          id: 'bcc_list',
          rule: [
            value => (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || !value) || '正しく入力してください。'
          ],
          // 追加ボタンを持つかどうか
          hasButton2: true
        },
        {
          label: '件名',
          disabled: false,
          icon: '',
          id: 'title',
          // required: true,
          rule: [
            value => !!value || '必須項目です。',
            value => (!!value && value.length <= 255) || '255文字以内で入力してください。'
          ]
        }
      ]
    }
  },
  components: {
    FilePond
  },
  computed: {
    progressClass () {
      if (this.progress) {
        return 'text-progress-center'
      }else{
        return 'text-progress-center display-none'
        // return 'text-progress-center'
      }
    }
  },
  methods: {
    handleFilePondUpdateFile(files){
      this.uploadFiles = files.map(files => files.file);
      let require = document.getElementById('requireFile');
      let title = document.getElementById('attachTitle');
      let icon = document.getElementById('attachIcon')
      require.style.visibility = '';
      title.classList.remove('redcolor');
      icon.classList.remove('redcolor');
    },
     addForm3() {
      let num = 2 + this.to_num;
      this.to_num++;
      this.form.splice(num, 0,
          {
                  label: '宛先',
                  disabled: false,
                  icon: 'mdi-account',
                  id: 'to_list_' + this.to_num + '_',
                  rule: [
                    value => (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || !value) || '正しく入力してください。'
                  ],
                  // 追加ボタンを持つかどうか
                  hasButton3: false,
                  hasButton4: true,
                }
      );
    },
    addForm() {
      let num = 3 + this.to_num + this.cc_num;
      this.cc_num++;
      this.form.splice(num, 0,
          {
                  label: 'CC',
                  disabled: false,
                  icon: 'mdi-account-multiple',
                  id: 'cc_list_' + this.cc_num + '_',
                  rule: [
                    value => (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || !value) || '正しく入力してください。'
                  ],
                  // 追加ボタンを持つかどうか
                  hasButton: false,
                  hasButton5: true,
                }
      );
    },
    addForm2() {
      let num = 4 + this.to_num + this.cc_num + this.bcc_num;
      this.bcc_num++;
      this.form.splice(num, 0,
          {
                  label: 'BCC',
                  disabled: false,
                  icon: 'mdi-account-multiple',
                  id: 'bcc_list_' + this.bcc_num + '_',
                  rule: [
                    value => (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || !value) || '正しく入力してください。'
                  ],
                  // 追加ボタンを持つかどうか
                  hasButton2: false,
                  hasButton6: true,
                }
      );
    },
      toggleForm1() {
      let num = 1 + this.to_num;
      this.to_num--;
      this.form.splice(num, 1);
    },
      toggleForm2() {
      let num = 2 + this.to_num + this.cc_num;
      this.cc_num--;
      this.form.splice(num, 1);
    },
      toggleForm3() {
      let num = 3 + this.to_num + this.cc_num + this.bcc_num;
      this.bcc_num--;
      this.form.splice(num, 1);
    },
    validate(){

    },
    doSend(){
        if (this.$refs.form.validate() && this.uploadFiles !== null && this.uploadFiles.length !== 0 ) {
            this.isPush = true;
            this.progress = true;
            this.progress_message = 'アップロード中...'

            let to_list_list = this.to_list
            if(this.to_num >= 1){
                for(let i = 0; i < this.to_num; i++){
                    let toidtext = 'to_list_' + (i+1) + '_' // + (i+6);
                    let t = document.getElementById(toidtext).value;
                    to_list_list += ',' + t;
                }
            }

            let cc_list_list = this.cc_list
            if(this.cc_num >= 1){
                for(let i = 0; i < this.cc_num; i++){
                    let ccidtext = 'cc_list_' + (i+1) + '_' // + (i+3);
                    let c = document.getElementById(ccidtext).value;
                    cc_list_list += ',' + c;
                }
            }

            let bcc_list_list = this.bcc_list
            if(this.bcc_num >= 1){
                for(let i = 0; i < this.bcc_num; i++){
                    let bccidtext = 'bcc_list_' + (i+1) + '_' // + (i+6);
                    let bc = document.getElementById(bccidtext).value;
                    bcc_list_list += ',' + bc;
                }
            }


            const formData = new FormData();
            formData.append("title", this.title);
            formData.append("user", this.$store.getters['auth/id']);
            formData.append("receiver_list", this.receiver_list);
            formData.append("to_list", to_list_list);
            formData.append("cc_list", cc_list_list);
            formData.append("bcc_list", bcc_list_list);
            formData.append("content", this.content);
            formData.append("receiver_list", this.receiver_list);
            formData.append("state", '1');

            for (let i = 0; i < this.uploadFiles.length; i++) {
                let file = this.uploadFiles[i];
                formData.append("files", file);
            }

            // 認証⽤トークンがあればリクエストヘッダに乗せる
            const token = localStorage.getItem('access')
            axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
            axios.post(process.env.VUE_APP_ROOT_URL + '/api/mail_create/', formData, {
              timeout: 5000000,
              headers: {
                'content-type': 'multipart/form-data',
              },
              onUploadProgress: this.onUpload
            })
            .then(response => {
              if (response != null && response.status === 200) {
                this.uploadFiles = [];
                this.receiver_list = "";
                this.to_list = "";
                this.cc_list = "";
                this.bcc_list = "";
                this.title = "";
                this.content = "";
                // this.$refs.pond.removeFile();
                this.message = "送信しました。"
                this.$refs.form.resetValidation();
                this.$refs.pond.removeFiles();
                this.isPush = false;
                this.progress = false;
                this.$router.push('/sendCertification')
              }
              this.isPush = false;
              this.progress = false;
              this.progress_message = ''
            })
            .catch(error => {
              if(error.code){
                // eslint-disable-next-line no-console
                console.log("Error")
                this.errors.push(error.message)
              }else if (error.response.status === 400) {
                this.content = error.response.data.content;
                // eslint-disable-next-line no-console
                console.log("400")
                this.$router.push('/')
              }else if (error.response.status === 401) {
                // eslint-disable-next-line no-console
                console.log("401")
                this.$router.push('/')
              }else if (error.response.status === 500) {
                // eslint-disable-next-line no-console
                console.log("500")
              } else if (error.response.status === 502) {
                this.content = error.response.data.content;
                // eslint-disable-next-line no-console
                console.log("502")
              }
              this.isPush = false;
              this.progress = false;
              this.progress_message = ''
            });
        } else {
            let require = document.getElementById('requireFile');
            let title = document.getElementById('attachTitle');
            let icon = document.getElementById('attachIcon')
            if(this.uploadFiles == null || this.uploadFiles.length === 0){
                require.style.visibility = 'visible';
                title.classList.add('redcolor');
                icon.classList.add('redcolor');
            }else{
                require.style.visibility = '';
                title.classList.remove('redcolor');
                icon.classList.remove('redcolor');
            }
        }
    },
    onUpload(e) {
      // プログレスバーを計算して変更
      let progress_value =  Math.floor((e.loaded * 100) / e.total);
      this.$refs.progress.value = progress_value;
      if(progress_value === 100){
        this.progress_message = "圧縮処理中"
      }
    },
    toSentMails() {
      this.$router.push('/sentMails')
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          let cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              let cookie = cookies[i].trim();
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
    },
    mounted(){
      this.resize();
    }
  }
}
</script>
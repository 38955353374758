<template>
    <v-container>
        <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="5"
            class="elevation-6 mt-3"
            @click:row="openUserinfo"
        >
            <template v-slot:top>
                <v-toolbar flat color="white" fixed>
                  <!-- タイトル -->
                  <v-toolbar-title>ユーザー一覧</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    export default {
        data () {
            return {
                headers: [
                    {
                        text: 'ユーザー名',
                        value: 'username'
                    },
                    {
                        text: 'メールアドレス',
                        value: 'address'
                    },
                    {
                        text: '承認者',
                        value: 'authorizer'
                    }
                ],
                users: [
                    {
                        username: 'konniesystem',
                        address: 'konniesystem.konniesystem.co.jp',
                        authorizer: 'なし'
                    },
                    {
                        username: 'ando',
                        address: 'ando.konniesystem.co.jp',
                        authorizer: 'konniesystem'
                    }
                ]
            }
        },
        methods: {
            openUserinfo () {
                this.$router.push('/admin/home/showUser')
            }
        }
    }
</script>
<template>
  <v-row align="center" justify="center" class="file-download">
    <v-col>
      <v-card width="500px" class="mx-auto mt-5 mb-5" elevation="6">
        <v-toolbar
        >
          <v-toolbar-title id="tool">
            kstransfer
          </v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="close"
              >
                <v-icon x-large>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>ウィンドウを閉じる</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="mt-4">
        <div v-if="errors.length" class="errorMessage">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">ファイル名</th>
                  <th class="text-center">ファイルサイズ</th>
                  <th class="text-center">ダウンロード</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-left">
                    <v-icon class="mr-1 mb-1">mdi-file</v-icon>{{ file_name }}
                  </td>
                  <td class="text-right">
                    {{ file_size }}
                  </td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            @click="download"
                        >
                          <v-icon color="black">mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <span>ダウンロード</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          </v-card-text>
      </v-card>
    </v-col>
<!--      <div :class="progressClass">-->
<!--          <v-progress-circular-->
<!--            :size="120"-->
<!--            :width="5"-->
<!--            color="primary"-->
<!--            indeterminate-->
<!--          >ダウンロード中</v-progress-circular>-->
<!--      </div>-->
      <div :class="progressClass" >
        <!-- プログレスバー（HTML5） -->
        <progress class="progress" ref="progress" value="0" max="100"></progress><br>
        <span class="text-muted">ダウンロード中</span>
      </div>
  </v-row>
</template>

<style>
  #tool {
    color: #0097A7
  }
.file-download .v-progress-circular {
  margin: 1rem;
}
.file-download .text-progress-center{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 30%;
  text-align: center;
  background: rgba(255,255,255,0.7);
}
.file-download .progress{
  height: 35px;
}
.file-download .display-none{
  display:none;
}
</style>

<script>
  import tokenApi from "@/services/token-api";
  import axios from "axios";

  export default {
    data() {
      return {
        progress: false,
        file_name: '',
        file_size: '',
        errors: []
      }
    },
    name: "downloadFile",
    // props: {
    //   progressflg: {
    //     type: Boolean,
    //     default: false
    //   }
    // },
    computed: {
      progressClass () {
        if (this.progress) {
          return 'text-progress-center'
        }else{
          return 'text-progress-center display-none'
        }
      }
    },
    methods: {
      download(){
        this.progress = true;
        const token = localStorage.getItem('token')
        const download_key = localStorage.getItem('download-key')
        let params = new URLSearchParams()
        params.append("key", download_key)
        params.append("token", token)
        axios.get(process.env.VUE_APP_ROOT_URL + '/api/download/' + download_key + '/', {
          params: params,
          responseType: "blob",
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          onDownloadProgress: this.onDownload
        })
        .then(response => {
          const status = response ? response.status : 500
          if (status === 200) {
            let link = document.createElement('a')
            let binaryData = [];
            binaryData.push(response.data);
            link.href = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
            let filename = "file.zip";
            let disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
              let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              let matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
              }
            }
            link.download = filename
            link.click()
            this.progress = false;
            this.$refs.progress.value = 0;
          } else if (status === 400) {
            console.log('エラー400：バリデーションNG')
          } else if (status === 401) {
            console.log('エラー401：認証エラー')
          }else if (status === 403) {
            // 権限エラー
            console.log('エラー403：認証エラー')
          }
        })
      },
      // download () {
      //   this.progress = true;
      //   const token = localStorage.getItem('token')
      //   const download_key = localStorage.getItem('download-key')
      //   this.$store.dispatch('token/authDownload', {
      //     // key: this.username,
      //     key: download_key,
      //     token: token
      //   })
      //   .then(() => {
      //     console.log('Download succeeded.')
      //     this.progress = false;
      //     // let link = document.createElement('a')
      //     // link.href = window.URL.createObjectURL(response)
      //     // link.download = 'ファイル名.xlsx'
      //     // link.click()
      //
      //     // let link = document.createElement('a')
      //     // let binaryData = [];
      //     // binaryData.push(data);
      //     // link.href = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
      //     // link.download = 'file.zip'
      //     // link.click()
      //   }).catch(error => {
      //     console.log('Download Failed.')
      //     this.progress = false;
      //     if(error.response.status === 404){
      //       console.log('The file does not exist.')
      //       // this.errorMessage = 'ファイルが存在しません。'
      //       this.errors.push('ファイルが存在しません。')
      //     } else if (error.payload.response.status === 401) {
      //       const token = localStorage.getItem('access')
      //       if (token === null) {
      //         // this.errorMessage = '認証されていません。'
      //         this.errors.push('認証されていません。')
      //       }
      //     }
      //   });
      // },
      close () {
        window.open('about:blank','_self').close();
      },
      onDownload(progressEvent) {
        let progress_value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.$refs.progress.value = progress_value;
      }
    },

    created() {
      const token = localStorage.getItem('token')
      const download_key = localStorage.getItem('download-key')
      let params = new URLSearchParams()
      params.append("key", download_key)
      params.append("token", token)
      return tokenApi.get(process.env.VUE_APP_ROOT_URL + `/api/showdownload/` + download_key + '/', {
        params: params,
        // responseType: "blob"
      }).then((response) => {
        this.file_name = response.data.send_mails[0].title
        this.file_size = response.data.send_mails[0].file_size
      })
    },
  }
</script>
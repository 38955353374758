import Vue from 'vue'
import Vuex from 'vuex'
import api from '../services/api.js'
import tokenApi from '../services/token-api.js'

Vue.use(Vuex)

// 認証情報
const authModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    id: '',
    username: '',
    isLoggedIn: false
  },
  getters: {
    id: state => state.id,
    username: state => state.username,
    isLoggedIn: state => state.isLoggedIn
  },
  mutations: {
    set(state, payload) {
      state.id = payload.user.id,
      state.username = payload.user.username,
      state.isLoggedIn = true
    },
    clear(state) {
      state.id = '',
      state.username = '',
      state.isLoggedIn = false
    }
  },
  actions: {
    /**
     * ログイン
     */
    login (context, payload) {
      // return api.post(`http://localhost:8000/api/v1/auth/jwt/create/`, {
      return api.post(process.env.VUE_APP_ROOT_URL + `/api/v1/auth/jwt/create/`, {
      // return api.post(`/api/v1/auth/jwt/create/`, {
        'username': payload.username,
        'password': payload.password
      })
      .then(response => {
        // 認証⽤トークンを localStorage に保存
        localStorage.setItem('access', response.data.access)
        // ユーザー情報を取得して store のユーザー情報を更新
        return context.dispatch('reload')
            .then(user => user)
      })
    },
    /**
     * ログアウト
     */
    logout (context) {
      // 認証⽤トークンを localStorage から削除
      localStorage.removeItem('access')
      // store のユーザー情報をクリア
      context.commit('clear')
    },
    /**
     * リロード
     */
    reload (context) {
      return api.get('/auth/users/me/')
        .then(response => {
          const user = response.data
          // store のユーザー情報を更新
          context.commit('set', {user: user})
          return user
        })
    }
  }
}

const tokenModule = {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    token: ''
  },
  getters: {
    token: state => state.id,
  },

  actions: {
    /**
     * ログイン
     */
    authPassword(context, payload) {
      console.log(payload.password)
      let params = new URLSearchParams()
      params.append("password", payload.password)
      // return tokenApi.get(`http://127.0.0.1:8000/api/passcheck/` + payload.key + '/', {
      return tokenApi.get(process.env.VUE_APP_ROOT_URL + `/api/passcheck/` + payload.key + '/', {
        params: params
      })
      .then(response => {
        console.log(response.data.token)
        // 認証⽤トークンを localStorage に保存
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('download-key', payload.key)
        // ユーザー情報を取得して store のユーザー情報を更新
        // return context.dispatch('reload').then(user => user)
        return ""
      })
    },
    authDownload(context, payload) {
      let params = new URLSearchParams()
      params.append("key", payload.key)
      params.append("token", payload.token)
      // return tokenApi.get(`http://127.0.0.1:8000/api/download/` + payload.key + '/', {
      return tokenApi.get(process.env.VUE_APP_ROOT_URL + `/api/download/` + payload.key + '/', {
        params: params,
        responseType: "blob"
      })
      .then(response => {
        // console.log(response.data.token)
        // 認証⽤トークンを localStorage に保存
        // localStorage.setItem('token', response.data.token)
        // ユーザー情報を取得して store のユーザー情報を更新
        // return context.dispatch('reload').then(user => user)

        let link = document.createElement('a')
        let binaryData = [];
        binaryData.push(response.data);
        link.href = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
        let filename = "file.zip";
        let disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        link.download = filename
        // link.download = 'file.zip'
        // link.download = response.headers.file.name
        link.click()
        return response
      })
    },
  }
}

const store = new Vuex.Store({
  modules: {
    auth: authModule,
    token: tokenModule
  },
//  status: {
//    token: ''
//  },
//  mutations: {
//    settoken (token) {
//      status.token = token
//    }
//  }
})

export default store

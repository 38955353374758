var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title'),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("承認確認メール一覧")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(!_vm.isMobile)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"cyan darken-3"},on:{"click":_vm.showDialog2}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-note ")])],1)]}}],null,false,1610628035)},[_c('span',[_vm._v("送信メール一覧")])])],1):_vm._e(),(_vm.isMobile)?_c('div',[_c('v-btn',{attrs:{"fab":"","outlined":"","color":"cyan darken-2"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-magnify ")])],1)],1):_vm._e(),(!_vm.isMobile)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","color":"cyan accent-4"},on:{"click":_vm.showDialog}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3279027295)},[_c('span',[_vm._v("新規作成")])])],1):_vm._e()],1),_c('v-row',{staticClass:"mx-4"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"show_dateDialog",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-3",attrs:{"slot":"activator","label":"送信日時","append-icon":"mdi-calendar","clearable":"","readonly":""},slot:"activator",model:{value:(_vm.filterSentDate),callback:function ($$v) {_vm.filterSentDate=$$v},expression:"filterSentDate"}},on))]}}]),model:{value:(_vm.show_dateDialog),callback:function ($$v) {_vm.show_dateDialog=$$v},expression:"show_dateDialog"}},[_c('v-date-picker',{attrs:{"scrollable":"","no-title":""},on:{"input":function($event){_vm.show_dateDialog=false}},model:{value:(_vm.filterSentDate),callback:function ($$v) {_vm.filterSentDate=$$v},expression:"filterSentDate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.state,"label":"状態","deletable-chips":"","chips":""},model:{value:(_vm.filterState),callback:function ($$v) {_vm.filterState=$$v},expression:"filterState"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"append-icon":"mdi-account","label":"送信者","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.sohinsyaName),callback:function ($$v) {_vm.sohinsyaName=$$v},expression:"sohinsyaName"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"append-icon":"mdi-account","label":"宛先","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filterTo),callback:function ($$v) {_vm.filterTo=$$v},expression:"filterTo"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-7",attrs:{"headers":_vm.headers,"items":_vm.maillists,"multi-sort":""},on:{"click":function($event){return _vm.openDetail(_vm.item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.openDetail(item)}}},[_c('td',[_c('v-chip',{staticClass:"short justify-center",attrs:{"color":_vm.getColor(item.state),"dark":""}},[_vm._v(_vm._s(item.state))])],1),_c('td',[_vm._v(" "+_vm._s(item.sentDate)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.sohinsyaName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.to)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.file)+" ")])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.state),"dark":""}},[_vm._v(" "+_vm._s(item.state)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'
import store from '../store/index'

// axios.defaults.baseURL = 'http://kstransfer.konniesystem.co.jp' //バックエンド側のIPとポート
// axios.defaults.headers.common['Accept'] = 'application/json'
// axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const api = new axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// 共通前処理
api.interceptors.request.use(
  function (config) {
    // 認証⽤トークンがあればリクエストヘッダに乗せる
    const token = localStorage.getItem('access')
    if (token) {
      config.headers.Authorization = 'JWT ' + token
      return config
    }
    return config  },
  function (error) {
    return Promise.reject(error)
  }
)

// 共通エラー処理
api.interceptors.response.use(function (response) {
  return response
}, function (error) {
    console.log('error.response=', error.response)
    const status = error.response ? error.response.status : 500
    if (status === 400) {
      // バリデーション NG
      console.log('エラー400：バリデーションNG')
    } else if (status === 401) {
      // 認証エラー
      const token = localStorage.getItem('access')
      if (token != null) {
        console.log('エラー401：ログイン認証切れ')
      } else {
        console.log('エラー401：認証エラー')
      }
      store.dispatch('auth/logout')
    }
    else if (status === 403) {
        // 権限エラー
        console.log('エラー403：認証エラー')
      } else {
        // その他のエラー
        console.log('想定外のエラーです。')
      }
    return Promise.reject(error)
  }
)

export default api

<template>
  <div>
    <!-- データテーブル -->
    <v-data-table
      :headers="headers"
      :items="sentMails"
      multi-sort
      :sort-by="['state', 'sentDate']"
      :sort-desc="[false, true]"
      class="elevation-1 mt-7"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <!-- タイトル -->
          <v-toolbar-title>送信済みメール</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
                    <!-- 新規作成アイコン(PC表示用) -->
          <div v-if="!isMobile">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  v-on="on"
                  color="teal lighten-1"
                  @click="showDialog2"
                  class="mx-2"
                >
                  <v-icon large>
                    mdi-account-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>承認一覧画面</span>
            </v-tooltip>
          </div>

          <!-- 新規作成アイコン(PC表示用) -->
          <div v-if="!isMobile">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  v-on="on"
                  color="cyan accent-4"
                  @click="showDialog"
                >
                  <v-icon large>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>新規作成</span>
            </v-tooltip>
          </div>
          <!-- 検索アイコン(スマートフォン表示用) -->
          <div v-if="isMobile">
            <v-btn
              fab
              outlined
              color="cyan darken-2"
              @click="activate"
            >
            <v-icon large>
              mdi-magnify
            </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-container v-if="isActive">
          <v-row>
            <!-- 送信日時フィルター -->
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4"
            >
            <v-menu
              ref="show_dateDialog"
              :close-on-content-click="false"
              v-model="show_dateDialog"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  slot="activator"
                  v-model="filterSentDate"
                  label="送信日時"
                  append-icon="mdi-calendar"
                  class="mt-3"
                  clearable
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="filterSentDate"
                scrollable
                no-title
                @input="show_dateDialog=false"
              />
            </v-menu>
            </v-col>
            <!-- 状態フィルター -->
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4"
            >
              <v-select
                :items="state"
                label="状態"
                deletable-chips
                chips
                v-model="filterState"
              />
            </v-col>
            <!-- 宛先フィルター -->
            <v-col
              cols="12"
              sm="4"
              md="4"
              lg="4"
              xl="4"
            >
              <v-text-field
                append-icon="mdi-account"
                label="宛先"
                single-line
                hide-details
                clearable
                class="mt-3"
                v-model="filterTo"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- データテーブルの内容 -->
      <template v-slot:item="{ item }">
        <tr @click="openDetail(item)">
           <!-- 状態 -->
           <td width="10">
            <v-icon v-show="item.state==='承認待ち'">
              mdi-key
            </v-icon>
          </td>
          <td>
            <v-chip :color="getColor(item.state)" dark class="short justify-center">
              {{ item.state }}
            </v-chip>
          </td>
          <!-- 送信日時 -->
          <td>
            {{ item.sentDate }}
          </td>
          <!-- 状態 -->
<!--          <td>-->
<!--            <v-chip :color="getColor(item.state)" dark>{{ item.state }}</v-chip>-->
<!--          </td>-->
          <!-- 宛先 -->
          <td>
            {{ item.to }}
          </td>
          <!-- 件名 -->
          <td>
            {{ item.title }}
          </td>
          <!-- 添付ファイル -->
          <td>
            {{ item.file }}
          </td>
          <!-- ダウンロード回数 -->
          <td class="text-right">
            {{ item.downloadCount }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <!-- 新規作成用アイコン(スマートフォン表示用) -->
    <v-btn
      fixed
      dark
      fab
      bottom
      right
      color="cyan darken-1"
      @click="showDialog"
      v-if="isMobile"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>
<style>
.short{
  width:80px;
}

</style>
<script>
  import * as sendMailApi from '../../services/get-api';
  export default {
    data () {
      return {
        // 検索用
        show_dateDialog: false,
        isActive: true,
        filterSentDate: '',
        filterTo: '',
        filterState: '',
        state: [
          '承認待ち',
          '済',
          '拒否'
        ],
        // テーブルの見出し部分
        headers: [
                  {
            text: '',
            value: 'cstate',
            filter: value => {
              if (!this.filterSentDate) {
                return true
              } else {
                // return value.slice(0,10) === this.filterSentDate
                return value === this.filterState
              }
            }
          },
            {
            text: '状態',
            value: 'state',
            filter: value => {
              if (!this.filterState) {
                return true
              } else {
                console.log(value)
                return value === this.filterState
              }
            }
          },
          { 
            text: '送信日時',
            value: 'sentDate',
            filter: value => {
              if (!this.filterSentDate) {
                return true
              } else {
                return value.slice(0,10) === this.filterSentDate
              }
            }
          },
          // {
          //   text: '状態',
          //   value: 'state',
          //   filter: value => {
          //     if (!this.filterState) {
          //       return true
          //     } else {
          //       console.log(value)
          //       return value === this.filterState
          //     }
          //   }
          // },
          {
            text: '宛先',
            value: 'to',
            filter: value => {
              if (!this.filterTo) return true
              return value != null && this.filterTo != null && value.indexOf(this.filterTo) !== -1
            }
          },
          { 
            text: '件名',
            value: 'title',
            sortable: false
          },
          { 
            text: '添付ファイル',
            value: 'file',
            sortable: false
          },
          { 
            text: 'ファイルダウンロード回数',
            value: 'downloadCount',
            sortable: false
          }
        ],
        // 送信済みメール
        sentMails: [
          // {
          //   sentDate: '2020-04-09',
          //   state: '承認待ち',
          //   to: 'konniesystem',
          //   title: 'グラウンド図面',
          //   file: 'groundMap.zip',
          //   downloadCount: 1
          // },
          // {
          //   sentDate: '2020-03-30',
          //   state: '送信済み',
          //   to: 'ishikawa',
          //   title: '校庭図面',
          //   file: 'shoolyard.zip',
          //   downloadCount: 5
          // }
        ]
      }
    },
    methods: {
      // 承認待ちかそうでないかでコンポーネントの色を変える
      getColor (state) {
        if (state === '承認待ち') return 'orange lighten-1'
        else if (state === '拒否') return 'red lighten-2'
        else return 'blue lighten-2'
      },
      // 新規作成ダイアログを表示
      showDialog () {
        // this.$refs.dialog.open()
        this.$router.push('/createNew')
      },
      // 新規作成ダイアログを表示
      showDialog2 () {
        // this.$refs.dialog.open()
        this.$router.push('/certificationList')
      },
      // メール詳細ページへデータを渡し、移動
      openDetail (item) {
        this.$router.push({name: 'showMail', params: this.sentMails[this.sentMails.indexOf(item)]})
      },
      activate () {
        this.isActive = !this.isActive
      }
    },
    computed: {
      // スマートフォンかどうか
      isMobile () {
        if(this.$vuetify.breakpoint.name === 'xs') {
          return true
        } else {
          return false
        }
      }
    },
    mounted () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        this.isActive = !this.isActive
      }
    },
    created() {
      const formData = new FormData();
      console.log(this.$store.getters['auth/id']);
      formData.append("user", this.$store.getters['auth/id']);
      sendMailApi.get(process.env.VUE_APP_ROOT_URL + '/api/mail', formData)
      .then((response) => {
        // console.log('succeeded.' + response.payload.data.send_mails)
        if (response.payload != null && response.payload.status === 200) {
            this.sentMails = response.payload.data.send_mails;
        }else{
            if (response.error.response.status === 400) {
              console.log("400")
              this.$router.push('/')
            }else if (response.error.response.status === 401) {
              console.log("401")
              this.$router.push('/')
            }else if (response.error.response.status === 500) {
              console.log("500")
              // this.$router.push('/')
            }
        }

        //this.sentMails = [{
        //       sentDate: '2020-04-09'
        //}]

      // }
        // this.$store.dispatch('api/mail', {
        //
        // })
        // .then(() => {
        //   console.log('succeeded.')
        //   this.sentMails = [
        //     {
        //       sentDate: '2020-04-09',
        //       state: '承認待ち',
        //       to: 'konniesystem',
        //       title: 'グラウンド図面',
        //       file: 'groundMap.zip',
        //       downloadCount: 1
        //     },
        //     {
        //       sentDate: '2020-03-30',
        //       state: '送信済み',
        //       to: 'ishikawa',
        //       title: '校庭図面',
        //       file: 'shoolyard.zip',
        //       downloadCount: 5
        //     }
        //   ]
        });
    }
  }
</script>

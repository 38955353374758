<template>
  <v-container>
    <v-card elevation="5" class="mt-4">
      <v-card-text>
        <v-spacer />
        <div class="text-center">
          <div>
            <v-icon style="font-size: 130px">
              mdi-account
            </v-icon>
          </div>
          <div class="mb-3 display-1">
            konniesystem
          </div>
          <v-divider />
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon small class="mb-1">
                    mdi-email
                  </v-icon>
                  メールアドレス
                </v-list-item-subtitle>
                <v-list-item-title>
                  konniesystem@konniesystem.co.jp
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-icon class="mb-1">
                      mdi-account-check
                    </v-icon>
                    承認者
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="showUserlist">
                          <v-icon large color="cyan darken-3">
                            mdi-cog
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>承認者設定</span>
                    </v-tooltip>
                    <setAuth ref="setAuthDialog" />
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    なし
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list>
          </div>
        </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import setAuth from "./setAuth";
export default {
  components: {
    setAuth
  },
  methods: {
    showUserlist () {
        this.$refs.setAuthDialog.open()
    }
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import login from '../components/login'
import sentMails from '../components/user/sentMails'
import createNew from '../components/user/createNew'
import showMail from '../components/user/showMail'
import adminHome from '../components/admin/home'
import showUser from '../components/admin/showUser'
import inputPass from "../components/user/receiver/inputPass"
import downloadFile from "../components/user/receiver/downloadFile"
import certificationMail from "../components/user/receiver/certificationMail"
import sendCompletely from "../components/user/receiver/sendCompletely"
import certificationList from "../components/user/receiver/certificationList"
import sendCertification from "../components/user/sendCertification"

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
    meta: { title: 'ログイン', desc: '' }
  },
  {
    path: '/sentMails',
    name: 'sentMails',
    component: sentMails,
    meta: {
      title: '送信済メール一覧',
      requiresAuth: true
    }
  },
  {
    path: '/createNew',
    name: 'createNew',
    component: createNew,
    meta: {
      title: '新規作成',
      requiresAuth: true
    }
  },
  {
    path: '/sentMails/showMail',
    name: 'showMail',
    component: showMail,
    meta: {
      title: 'メール詳細',
      requiresAuth: true
    }
  },
  {
    path: '/admin/home',
    name: 'Home',
    component: adminHome,
    meta: {
      title: 'Home',
      requiresAuth: true
    }
  },
  {
    path: '/admin/home/showUser',
    name: 'showUser',
    component: showUser,
    meta: {
      title: 'showUser',
      requiresAuth: true
    }
  },
  {
    path: '/inputPass',
    name: 'inputPass',
    component: inputPass,
    meta: {
      title: 'パスワード入力',
      requiresAuth: true
    }
  },
  {
    path: '/downloadFile',
    name: 'downloadFile',
    component: downloadFile,
    meta: {
      title: 'ダウンロード'
    }
  },
    {
      path: '/certificationMail',
      name: 'certificationMail',
      component: certificationMail,
      meta: {
        title: 'certificationMail'
      }
    },
    {
      path: '/sendCompletely',
      name: 'sendCompletely',
      component: sendCompletely,
      meta: {
        title: 'sendCompletely'
      }
    },
    {
      path: '/certificationList',
      name: 'certificationList',
      component: certificationList,
      meta: {
        title: 'certificationList'
      }
    },
    {
      path: '/sendCertification',
      name: 'sendCertification',
      component: sendCertification,
      meta: {
        title: 'sendCertification'
      }
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Router によって画面遷移する際に毎回実行される
 */
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn']
  const token = localStorage.getItem('access')
  console.log('to.path=', to.path)
  console.log('isLoggedIn=', isLoggedIn)
  // ログインが必要な画面に遷移しようとした場合
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // ログインしている状態の場合
    if (isLoggedIn) {
      console.log('User is already logged in. So, free to next.')
      next()
      // ログインしていない状態の場合
    } else {
      if(to.path === '/inputPass'){
        // ダウンロードの場合はログイン画面に遷移させない
        console.log('Go To Download Page')
        next()
      }else if (token != null) {
        // まだ認証用トークンが残っていればユーザー情報を再取得
        console.log('User is not logged in. Trying to reload again.')
        store.dispatch('auth/reload')
          .then(() => {
            // 再取得できたらそのまま次へ
            console.log('Succeeded to reload. So, free to next.')
            next()
          })
          .catch(() => {
            // 再取得できなければログイン画⾯へ
            forceToLoginPage(to, from, next)
          })
      } else {
        // 認証用トークンが無い場合は、 ログイン画面へ
        forceToLoginPage(to, from, next)
      }
    }
  } else {
    // ログインが不要な画面であればそのまま次へ
    console.log('Go to public page.')
    next()
  }
})

/**
 * ログイン画面へ強制送還
 */
function forceToLoginPage (to, from, next) {
  console.log('Force user to login page.')
  next({
    path: '/',
    // 遷移先のURLはクエリ文字列として付加
    query: {next: to.fullPath}
  })
}
/* eslint-disable */
router.afterEach((to, from) => {
  document.title = to.meta.title
})
/* eslint-enable */
export default router

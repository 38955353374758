<template>
  <v-dialog
  v-model="setAuthDialog"
  width="600px"
  >
    <v-card>
      <v-toolbar
        color="cyan"
        dark
      >
        <v-toolbar-title>
            承認者設定
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="close"
        >
          <v-icon x-large>
            mdi-close-circle-outline
          </v-icon>
        </v-btn>
      </v-toolbar>
        <v-card-text>
          <v-text-field
          label="以下より選択してください。"
          deletable-chips
          chips
          class="mt-6"/>
          <v-container>
            <v-card
            color="grey lighten-3"
            flat
            >
              <v-card-text>
                <v-list
                color="grey lighten-3"
                max-height="200px"
                >
                  <v-list-item
                  v-for="(item, i) in userlist"
                  :key="i"
                  @click="toTextfield">
                    <v-list-item-icon>
                      <v-icon>
                        mdi-account
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.address }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>
                        mdi-account-plus
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.v-list {
  overflow-y: auto;
}
</style>

<script>
export default {
  data () {
    return {
      setAuthDialog: false,
      userlist: [
        {
          name: 'konniesystem',
          address: 'mailaddress.address.co.jp'
        },
        {
          name: 'yano',
          address: 'mailaddress.address.co.jp'
        },
        {
          name: 'ishikawa',
          address: 'mailaddress.address.co.jp'
        },
        {
          name: 'tamai',
          address: 'mailaddress.address.co.jp'
        },
        {
          name: 'ando',
          address: 'mailaddress.address.co.jp'
        }
      ]
    }
  },
  methods: {
    open() {
      this.setAuthDialog = true
    },
    close() {
      this.setAuthDialog = false
    },
    toTextfield() {

    }
  }
}
</script>
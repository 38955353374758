var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-7",attrs:{"headers":_vm.headers,"items":_vm.sentMails,"multi-sort":"","sort-by":['state', 'sentDate'],"sort-desc":[false, true]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("送信済みメール")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(!_vm.isMobile)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"teal lighten-1"},on:{"click":_vm.showDialog2}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-account-arrow-left ")])],1)]}}],null,false,3534631353)},[_c('span',[_vm._v("承認一覧画面")])])],1):_vm._e(),(!_vm.isMobile)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","color":"cyan accent-4"},on:{"click":_vm.showDialog}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,3279027295)},[_c('span',[_vm._v("新規作成")])])],1):_vm._e(),(_vm.isMobile)?_c('div',[_c('v-btn',{attrs:{"fab":"","outlined":"","color":"cyan darken-2"},on:{"click":_vm.activate}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-magnify ")])],1)],1):_vm._e()],1),(_vm.isActive)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('v-menu',{ref:"show_dateDialog",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-3",attrs:{"slot":"activator","label":"送信日時","append-icon":"mdi-calendar","clearable":"","readonly":""},slot:"activator",model:{value:(_vm.filterSentDate),callback:function ($$v) {_vm.filterSentDate=$$v},expression:"filterSentDate"}},on))]}}],null,false,2084242581),model:{value:(_vm.show_dateDialog),callback:function ($$v) {_vm.show_dateDialog=$$v},expression:"show_dateDialog"}},[_c('v-date-picker',{attrs:{"scrollable":"","no-title":""},on:{"input":function($event){_vm.show_dateDialog=false}},model:{value:(_vm.filterSentDate),callback:function ($$v) {_vm.filterSentDate=$$v},expression:"filterSentDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('v-select',{attrs:{"items":_vm.state,"label":"状態","deletable-chips":"","chips":""},model:{value:(_vm.filterState),callback:function ($$v) {_vm.filterState=$$v},expression:"filterState"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"append-icon":"mdi-account","label":"宛先","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filterTo),callback:function ($$v) {_vm.filterTo=$$v},expression:"filterTo"}})],1)],1)],1):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.openDetail(item)}}},[_c('td',{attrs:{"width":"10"}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.state==='承認待ち'),expression:"item.state==='承認待ち'"}]},[_vm._v(" mdi-key ")])],1),_c('td',[_c('v-chip',{staticClass:"short justify-center",attrs:{"color":_vm.getColor(item.state),"dark":""}},[_vm._v(" "+_vm._s(item.state)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(item.sentDate)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.to)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.file)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.downloadCount)+" ")])])]}}])}),(_vm.isMobile)?_c('v-btn',{attrs:{"fixed":"","dark":"","fab":"","bottom":"","right":"","color":"cyan darken-1"},on:{"click":_vm.showDialog}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-container>
      <v-btn outlined rounded color="cyan darken-4" @click="toSentMails">送信済みメール一覧へ</v-btn>
      <v-card  max-width="850" class="mx-auto mt-5 mb-5" elevation="7">
        <v-card-title>
          メール詳細
          <v-icon class="ml-2">
            mdi-email-outline
          </v-icon>
          <v-spacer />
        </v-card-title>
        <!-- 送信日時 -->
        <v-card-text>
          送信日時：
          {{ $route.params.sentDate }}
          <v-row>
            <!-- 承認者 -->
            <v-col cols="8">
              <v-text-field
                label="承認者"
                disabled
                dense
                prepend-icon="mdi-account-check"
                v-model="$route.params.receiver"
              />
            </v-col>
            <!-- 状態 -->
            <v-col cols="2">
              <v-chip
                :color="getColor($route.params.state)"
                dark
              >
                {{ $route.params.state }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <!-- 宛先 -->
            <v-col cols="12">
              <v-text-field
                label="宛先"
                disabled
                dense
                prepend-icon="mdi-account"
                v-model="$route.params.to"
              />
            </v-col>
            </v-row>
            <v-row>
            <!-- CC -->
            <v-col cols="12">
              <v-text-field
                label="CC"
                disabled
                dense
                prepend-icon="mdi-account-multiple"
                v-model="$route.params.cc"
              />
            </v-col>
            </v-row>
            <v-row>
            <!-- BCC -->
            <v-col cols="12">
              <v-text-field
                label="BCC"
                disabled
                dense
                prepend-icon="mdi-account-multiple"
                v-model="$route.params.bcc"
              />
            </v-col>
          </v-row>
      <!-- 件名 -->
      <v-text-field
        label="件名"
        disabled
        dense
        v-model="$route.params.title"
      />
      <!-- 添付ファイル -->
      <v-text-field
        label="添付ファイル"
        disabled
        dense
        v-model="$route.params.file"
      />
      <!-- div class="mb-1">
        添付ファイル
        <v-icon>
          mdi-paperclip
        </v-icon>
      </div>
      <file-pond
        allowMultiple="true"
        disabled="true"
      / -->
      <!-- 本文 -->
      <v-textarea
        autocomplete="email"
        label="本文"
        disabled
        dense
        counter
        readonly="true"
        v-model="$route.params.content"
        height="auto"
        auto-grow
      />
    </v-card-text>

      </v-card>
    </v-container>
  </div>
</template>

<style>
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: black!important;
}
</style>
<script>
export default {
  data () {
    return {
      // emailData: []
    }
  },
  methods: {
            getColor (state) {
            if (state === '承認待ち') return 'orange lighten-1'
            else if (state === '拒否') return 'red lighten-2'
            else return 'blue lighten-2'
        },
    toSentMails () {
      // this.$router.push('/sendMails')
      this.$router.back()
    }

  }
}
</script>

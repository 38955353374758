<template>
  <div class="application-header">
    <v-app-bar
      dark
      color="cyan darken-1"
    >
      <!-- タイトル -->
      <v-toolbar-title class="ml-4 title">
        kstransfer
      </v-toolbar-title>
      <v-spacer />
      <!-- ログインしていたらユーザー名を表示 -->
      <div class="mr-3" v-if="this.$route.path !== '/'">
        <!-- PC表示用 -->
        <div v-if="!isMobile">
          <v-icon large>
            mdi-account-circle
          </v-icon>
          ユーザー名 : {{ username }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="logout">
                <v-icon dark midium>
                  mdi-logout
                </v-icon>
              </v-btn>
            </template>
            <span>ログアウト</span>
          </v-tooltip>
        </div>
        <!-- スマホ表示用 -->
        <div v-else>
          <v-menu
            left
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon large>
                  mdi-account-circle
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-layout justify-center>
                  <div>
                    <v-icon large>
                      mdi-account-circle
                    </v-icon>
                  </div>
                </v-layout>
                <v-layout justify-center>
                  <div class="mb-1">
                    ユーザー名：{{ username }}
                  </div>
                </v-layout>
                <v-divider />
                <div @click="logout" class="mt-3">
                  ログアウト
                  <v-icon>
                    mdi-logout
                  </v-icon>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>
<style>
.application-header{
  height: 40px!important;
}
.application-header .v-toolbar{
  height: 40px!important;
}
.application-header .v-toolbar__content{
  height: 40px!important;
}
.application-header .mdi-account-circle{
  font-size: 24px!important;
}
.application-header .mr-3{
  font-size: 14px;
}
</style>
<script>
export default {
  data () {
    return {
      title: 'ログアウト'
    }
  },
  methods: {
    // ログアウト
    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/')
    }
  },
  computed: {
    isMobile () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return true
      } else {
        return false
      }
    },
    username: function () {
      return this.$store.getters['auth/username']
    },
    isLoggedIn: function () {
      return this.$store.getters['auth/isLoggedIn']
    }
  }
}
</script>

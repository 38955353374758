<template>
  <v-app id="app">
    <!-- ヘッダー -->
    <Header v-if="this.$route.path.indexOf('inputPass') === -1 && this.$route.path.indexOf('downloadFile') === -1"/>
    <!-- コンポーネントを挿入 -->
    <router-view />
    <!-- フッター -->
    <Footer v-if="this.$route.path.indexOf('inputPass') === -1 && this.$route.path.indexOf('downloadFile') === -1"/>
  </v-app>
</template>

<style>
/* google fontsをインポート */
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap');

#app {
  font-family: 'Noto Sans JP', sans-serif;
  overflow: hidden;
}
</style>

<script>
import Header from './components/header'
import Footer from './components/footer'
export default {
  name: 'App',

  components: {
    Header,
    Footer
  }
};
</script>

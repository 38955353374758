<template>
  <v-dialog v-model="pass" width="350" persistent>
    <v-card>
      <v-card-title class="justify-center">パスワードを入力</v-card-title>
      <v-card-text>
        コンテンツはパスワードで保護されています。<br>続行するにはパスワードを入力してください。
        <!-- パスワードの入力フォーム -->
        <v-text-field
          class="mt-4"
          v-bind:type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          label="パスワード"
          @click:append="showPassword = !showPassword"
          v-model="password"
          name="password"
        >
        </v-text-field>
        <!-- ログインボタンの配置 -->
        <v-card-actions class="justify-center">
          <v-btn
            class="cyan darken-1"
            dark
            @click="toDownload"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    </v-dialog>
</template>

<script>
//  import axios from 'axios';

  export default {
    name: "inputPass",
    data () {
      return {
        info: null,
        // 目のアイコンをデフォルトで閉じる
        showPassword: false,
        password: '',
        pass: true
      }
    },
    methods: {
      toDownload () {
        // パスワード認証
        var params = new URLSearchParams()
        params.append("password", this.password)
//        params.append("pk", this.$route.query.key)
        console.log(process.env.VUE_APP_ROOT_URL + '/api/passcheck/' + this.$route.query.key + '/')
        // axios.get('http://127.0.0.1:8000/api/passcheck/' + this.$route.query.key + '/', { params: params})
        this.$store.dispatch('token/authPassword', {
            key: this.$route.query.key,
            password: this.password
        })
        .then(() => {
          console.log('Login succeeded.')
          this.$router.push('/downloadFile')
          // this.$router.push({ name: '/downloadFile', params: {key: this.$route.query.key}})
        }).catch(error => {
          if (error.response.status === 401) {
            const token = localStorage.getItem('token')
            if (token === null) {
              this.errorMessage = 'パスワードに誤りがあります。'
            }
          }
        });

//        .then(response => {
//            console.log(response.status)
//            if (response.status == 200) {
//                console.log(response.data)
//                this.dialog = false
//                console.log(this.store.token.token)
//                this.$router.push('/downloadFile')
//            }else{
//                this.info = response.data
//                console.log(response.status)
//            }
//        }).catch(error => {
//            console.log(error);
//        });
      }
    }
  }
</script>
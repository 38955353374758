<template>
  <div>
    <v-container>
      <v-card elevation="7"  width="900" class="mx-auto mt-10 mb-10">
        <v-card-title>
          メール承認完了
          <v-icon class="ml-2">
            mdi-email-outline
          </v-icon>
          <v-spacer />
        </v-card-title>
        <!-- 送信日時 -->
        <v-card-text>
送信しました。
    </v-card-text>
    <div class="text-center">
    <router-link to="/certificationList">
        <v-btn
          color="primary lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          class="mb-3"
          width="100"
        >
          戻る
        </v-btn>
        </router-link>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // emailData: []
      dialog: false,
      checkbox: false,
singleSelect: false,
      isValid: true
    }
  },
  methods: {
      isLastitem (index) {
      return this.item.length -1 === index    },

    submit () {
      if (!this.$refs.form.validate()) {
        this.isValid = false
      }},
    getColor (state) {
        if (state === '承認待ち') return 'red lighten-2'
        else return 'green lighten-1'
    },
    toSentMails () {
      // this.$router.push('/sendMails')
      this.$router.back()
    }
  }
}
</script>
<template>
  <div>
    <v-container>
      <v-btn outlined rounded color="cyan darken-4" @click="tocertificationList">承認確認メール一覧へ</v-btn>
      <v-card max-width="850" class="mx-auto mt-5 mb-5" elevation="7">
        <v-card-title>
          承認メール確認
          <v-icon class="ml-2">
            mdi-email-outline
          </v-icon>
          <v-spacer/>
        </v-card-title>

        <!-- 送信日時 -->
        <v-card-text>
          送信日時：
          {{ $route.params.sentDate }}
            <!-- 状態 -->
              <v-chip
                :color="getColor($route.params.state)"
                dark
                id="state"
              >
                {{ $route.params.state }}
              </v-chip>
          <v-row>
            <v-card-text v-if="$route.params.state==='承認待ち'">
          ※すべてチェック後、承認ボタンが出てきます。
        </v-card-text>
                        <v-card-text v-else>
        </v-card-text>
            <div class="ml-5" v-if="$route.params.state==='承認待ち'">
              <v-checkbox id="user_check" v-model="user_check"></v-checkbox>
            </div>
            <!-- 送信者 -->
            <v-col cols="11">
              <v-text-field
                  label="送信者"
                  disabled
                  dense
                  prepend-icon="mdi-account-check"
                  v-model="$route.params.sohinsyaName"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- 宛先 -->
            <div class="ml-5" v-if="$route.params.state==='承認待ち'">
              <v-checkbox id="to_check" v-model="to_check"></v-checkbox>
            </div>
            <v-col cols="11">
              <v-text-field
                  label="宛先"
                  disabled
                  dense
                  prepend-icon="mdi-account"
                  v-model="$route.params.to"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- CC -->
            <div class="ml-5" v-if="$route.params.state==='承認待ち'">
              <v-checkbox id="cc_check" v-model="cc_check"></v-checkbox>
            </div>
            <v-col cols="11">
              <v-text-field
                  label="CC"
                  disabled
                  dense
                  prepend-icon="mdi-account-multiple"
                  v-model="$route.params.cc"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- BCC -->
            <div class="ml-5" v-if="$route.params.state==='承認待ち'">
              <v-checkbox id="bcc_check" v-model="bcc_check"></v-checkbox>
            </div>
            <v-col cols="11">
              <v-text-field
                  label="BCC"
                  disabled
                  dense
                  prepend-icon="mdi-account-multiple"
                  v-model="$route.params.bcc"
              />
            </v-col>
          </v-row>
          <!-- 件名 -->
          <v-row>
            <div class="ml-5" v-if="$route.params.state==='承認待ち'">
              <v-checkbox id="title_check" v-model="title_check"></v-checkbox>
            </div>
            <v-col cols="11">
              <v-text-field
                  label="件名"
                  disabled
                  dense
                  v-model="$route.params.title"
              />
            </v-col>
          </v-row>
          <!-- 添付ファイル -->
          <v-row>
            <div class="ml-5" v-if="$route.params.state==='承認待ち'">
              <v-checkbox id="attachTitle_check" v-model="attachTitle_check"></v-checkbox>
            </div>
            <v-col cols="11">
              <v-text-field
                  label="添付ファイル"
                  disabled
                  dense
                  v-model="$route.params.file"
              />
            </v-col>
          </v-row>
          <v-row>
            <div class="ml-5" v-if="$route.params.state==='承認待ち'">
              <v-checkbox id="content_check" v-model="content_check"></v-checkbox>
            </div>
            <v-col cols="11">
              <v-textarea
                  autocomplete="email"
                  label="本文"
                  disabled
                  dense
                  counter
                  v-model="$route.params.content"
                  auto-grow
              />
            </v-col>
          </v-row>
          <v-row v-if="$route.params.state==='承認待ち'">
          <v-col  align="center">
              <v-dialog
                  v-model="dialog2"
                  width="500">
                <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="grey"
              dark
              class="mb-3"
              rounded
              v-bind="attrs"
              v-on="on"
          >
            承認しない
            <v-icon class="ml-2" small>
              mdi-hand-back-right-outline
            </v-icon>
          </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    確認
                  </v-card-title>

                  <v-card-text>
                    こちらのメールの承認を拒否しますか？
                    <v-row>
                      <v-col align="center">
<!--                        <router-link to="/certificationList" tag="button">-->
                          <v-btn
                              color="red lighten-2"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="mx-3"
                              width="100"
                              @click="dialog2 = false , stateChange();"
                          >
                            はい
                          </v-btn>
<!--                        </router-link>-->
                        <v-btn
                            color="primary lighten-2"
                            dark
                            v-bind="attrs"
                            width="100"
                            @click="dialog2 = false">
                          戻る
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-col>
          </v-row>
          <div
              v-if="user_check && to_check && cc_check && bcc_check && title_check && attachTitle_check && content_check">
            <div class="text-center">
              <v-dialog
                  v-model="dialog"
                  width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="cyan darken-2"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="mb-3"
                      width=""
                      rounded
                      @click="submit"
                      :disabled="isDisabled"
                      v-if="$route.params.state==='承認待ち'"
                  >
                    承認する
                    <v-icon class="ml-2" small>
                      mdi-send
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    確認
                  </v-card-title>

                  <v-card-text>
                    こちらのメールを承認し送信しますか？
                    <v-row>
                      <v-col align="center">
<!--                        <router-link to="/sendCompletely" tag="button">-->
                          <v-btn
                              color="red lighten-2"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="mx-3"
                              width="100"
                              @click="dialog = false , stateChangeApproval()"
                          >
                            送信
                          </v-btn>
<!--                        </router-link>-->
                        <v-btn
                            color="primary lighten-2"
                            dark
                            v-bind="attrs"
                            width="100"
                            @click="dialog = false">
                          戻る
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>

        </v-card-text>

      </v-card>
    </v-container>
  </div>
</template>

<style>
.theme--light.v-icon.v-icon.v-icon--disabled {
  color: #00bcd4 !important;
}

.v-application .align-center {
  margin-top: -2rem !important;
}

a {
  text-decoration: none;
}
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: black!important;
}
</style>

<script>
export default {
  data() {
    return {
      // emailData: []
      show: false,
      user_check: false,
      to_check: false,
      cc_check: false,
      bcc_check: false,
      title_check: false,
      attachTitle_check: false,
      content_check: false,
      state: [
          '承認待ち',
          '済',
          '拒否'
        ],
      dialog: false,
      dialog2: false,
    }
  },
  methods: {
    getColor (state) {
      if (state === '承認待ち') return 'orange lighten-1'
      else if (state === '拒否') return 'red lighten-2'
      else return 'blue lighten-2'
    },
    tocertificationList() {
      this.$router.push('/certificationList')
    },
    stateChange(){
      // eslint-disable-next-line no-console
      console.log(this.$route.params.state)
      this.$route.params.state = '拒否'
      alert('このメールを拒否しました。')
      // this.$router.push('/certificationList')
    },
    stateChangeApproval(){
      // eslint-disable-next-line no-console
      console.log(this.$route.params.state)
      this.$route.params.state = '済'
      alert('このメールを送信しました。')
      // this.$router.push('/certificationList')
    }
  }
}
</script>

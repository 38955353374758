<template>

  <v-card>
  <v-card-title>
    </v-card-title>

        <v-toolbar flat color="white">
          <!-- タイトル -->
          <v-toolbar-title>承認確認メール一覧</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
           <!-- 新規作成アイコン(PC表示用) -->
          <div v-if="!isMobile">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  v-on="on"
                  color="cyan darken-3"
                  @click="showDialog2"
                  class="mx-2"
                >
                  <v-icon large>
                    mdi-note
                  </v-icon>
                </v-btn>
              </template>
              <span>送信メール一覧</span>
            </v-tooltip>
          </div>
          <!-- 検索アイコン(スマートフォン表示用) -->
          <div v-if="isMobile">
            <v-btn
              fab
              outlined
              color="cyan darken-2"
            >
            <v-icon large>
              mdi-magnify
            </v-icon>
            </v-btn>
          </div>
            <!-- 新規作成アイコン(PC表示用) -->
          <div v-if="!isMobile">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  v-on="on"
                  color="cyan accent-4"
                  @click="showDialog"
                >
                  <v-icon large>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>新規作成</span>
            </v-tooltip>
          </div>

</v-toolbar>

    <v-row class="mx-4">
               <!-- 送信日時フィルター -->
            <v-col
              cols="3"
            >
            <v-menu
              ref="show_dateDialog"
              :close-on-content-click="false"
              v-model="show_dateDialog"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  slot="activator"
                  v-model="filterSentDate"
                  label="送信日時"
                  append-icon="mdi-calendar"
                  class="mt-3"
                  clearable
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="filterSentDate"
                scrollable
                no-title
                @input="show_dateDialog=false"
              />
            </v-menu>
            </v-col>
            <!-- 状態フィルター -->
            <v-col cols="3">
              <v-select
                :items="state"
                label="状態"
                deletable-chips
                chips
                v-model="filterState"
              />
            </v-col>
           <!-- 送信者フィルター -->
            <v-col
              cols="3"
            >
              <v-text-field
                append-icon="mdi-account"
                label="送信者"
                single-line
                hide-details
                clearable
                class="mt-3"
                v-model="sohinsyaName"
              />
            </v-col>
            <!-- 宛先フィルター -->
            <v-col
              cols="3"
            >
              <v-text-field
                append-icon="mdi-account"
                label="宛先"
                single-line
                hide-details
                clearable
                class="mt-3"
                v-model="filterTo"
              />
            </v-col>
          </v-row>

    <v-data-table
      :headers="headers"
      :items="maillists"
      multi-sort
      class="elevation-1 mt-7"
      @click="openDetail(item)"
    >
        <template v-slot:item="{ item }">
        <tr @click="openDetail(item)">
          <!-- 状態 -->
          <td>
            <v-chip :color="getColor(item.state)" dark class="short justify-center">{{ item.state }}</v-chip>
          </td>
          <td>
            {{ item.sentDate }}
          </td>

          <td>
            {{ item.sohinsyaName}}
          </td>
          <td>
            {{ item.to }}
          </td>
            <td>
            {{ item.title }}
          </td>
           <td>
            {{ item.file }}
          </td>
        </tr>
      </template>
        <template v-slot:item.state="{ item }">
            <v-chip
            :color="getColor(item.state)"
            dark
            >
            {{ item.state }}
            </v-chip>
        </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import * as API from '../../../services/get-api';
  export default {
    data () {
      return {
        // 検索用
        show_dateDialog: false,
        isActive: true,
        filterSentDate: '',
        filterTo: '',
        filterUser: '',
        filterState: '',
        sohinsyaName:'',
        state: [
          '承認待ち',
          '済',
          '拒否'
        ],
        // テーブルの見出し部分
        headers: [
          {
            text: '状態',
            // align: 'start',
            // filterable: false,
            value: 'state',
            filter: value => {
              if (!this.filterState) {
                return true
              } else {
                console.log(value)
                return value === this.filterState
              }
            }
          },
          {
            text: '送信日時',
            value: 'sentDate',
            filter: value => {
              if (!this.filterSentDate) {
                return true
              } else {
                return value.slice(0,10) === this.filterSentDate
              }
            }
          },
          {
            text: '送信者',
            value: 'sohinsyaName',
            filter: value => {
              if (!this.sohinsyaName) return true
              return value != null && this.sohinsyaName != null && value.indexOf(this.sohinsyaName) !== -1
            }
          },
          {
            text: '宛先',
            value: 'to' ,
            filter: value => {
              if (!this.filterTo) return true
              return value != null && this.filterTo != null && value.indexOf(this.filterTo) !== -1
            }
          },
          {
            text: '件名',
            value: 'title'
          },
          {
            text: '添付ファイル',
            value: 'file',
            sortable: false
          },
        ],
        maillists: [
          {
            // state: '未承認',
            // sentDate: '2021-09-28',
            // sohinsyaName: 'mail@mail.co.jp',
            // to: 'mail@mail.co.jp',
            // title: 'テスト'
          }
        ],
      }
    },
    methods: {
        // 承認待ちかそうでないかでコンポーネントの色を変える
            getColor (state) {
            if (state === '承認待ち') return 'orange lighten-1'
            else if (state === '拒否') return 'red lighten-2'
            else return 'blue lighten-2'
        },
        // メール詳細ページへデータを渡し、移動
        openDetail (item) {
            this.$router.push({name: 'certificationMail', params: this.maillists[this.maillists.indexOf(item)]})
        },      // 新規作成ダイアログを表示
      showDialog () {
        // this.$refs.dialog.open()
        this.$router.push('/createNew')
      },
           // 新規作成ダイアログを表示
      showDialog2 () {
        // this.$refs.dialog.open()
        this.$router.push('/sentMails')
      },
      activate () {
        this.isActive = !this.isActive
      }
    },
    computed: {
        // スマートフォンかどうか
        isMobile () {
            if (this.$vuetify.breakpoint.name === 'xs') {
            return true
            } else {
            return false
            }
        }
    },
    created() {
        const formData = new FormData();
        console.log(this.$store.getters['auth/id']);
        formData.append("user", this.$store.getters['auth/id']);
        // API.get(process.env.VUE_APP_ROOT_URL + '/api/mail_certification', formData)
      API.get(process.env.VUE_APP_ROOT_URL + '/api/mail_certification', formData)
        .then((response) => {
            // console.log('succeeded.' + response.payload.data.send_mails)
            if (response.payload != null && response.payload.status == 200) {
                this.maillists = response.payload.data.send_mails;
            }else{
                if (response.error.response.status == 400) {
                  console.log("400")
                  // this.$router.push('/')
                }else if (response.error.response.status == 401) {
                 console.log("401")
                 // this.$router.push('/')
                }else if (response.error.response.status == 500) {
                 console.log("500")
                  // this.$router.push('/')
                }
            }
        });
    },
  }
</script>
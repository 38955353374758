<template>
  <v-card
    width="310px"
    class="mx-auto mt-5 mb-5"
    elevation="6"
  >
    <v-card-title class="justify-center">
      <span class="loginCardTitle">ログイン</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="loginForm">
        <!-- ユーザー名の入力フォーム -->
        <v-text-field
          prepend-icon="mdi-account-circle"
          label="ユーザー名"
          type="text"
          v-model="username"
          name="username"
          :rules="login_rule"
          class="mb-2"
        />
        <!-- パスワードの入力フォーム -->
        <v-text-field
          v-bind:type="showPassword ? 'text' : 'password'"
          prepend-icon="mdi-lock"
          v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          label="パスワード"
          @click:append="showPassword = !showPassword"
          v-model="password"
          name="password"
          :rules="login_rule"
          class="mb-3"
        />
        <div class="errorMessage">
          {{ errorMessage }}
        </div>
        <!-- ログインボタンの配置 -->
        <v-card-actions class="justify-center">
        <v-btn
          class="cyan darken-1"
          dark
          @click="login"
        >
            ログイン
          <v-icon>mdi-login</v-icon>
        </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style>
li {
  list-style-type: none;
}
.loginCardTitle {
  font-size: 30px;
}
.errorMessage {
  color: #E53935;
}
</style>

<script>

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
    errorMessage: '',
    // 目のアイコンをデフォルトで閉じる
    showPassword: false,
    // バリデーション
    login_rule: [
        value => !!value || '入力してください。'
    ]
  }),
  methods: {
    login () {
      // ログイン
      if (this.$refs.loginForm.validate()) {
        this.$store.dispatch('auth/login', {
         username: this.username,
         password: this.password
        })
        .then(() => {
          // console.log('Login succeeded.')
          this.$router.push('/createNew')
        }).catch(error => {
          //
          // console.log(error.message);
          // if(error.message !== ""){
          //   // ネットワークエラー
          //   // this.errorMessage = 'サーバにアクセスできませんでした。';
          //   this.errorMessage = error.message;
          // }else
          if(error.response === undefined){
            this.errorMessage = 'サーバにアクセスできませんでした。';
          } else if (error.response.status === 401) {
            const token = localStorage.getItem('access')
            if (token === null) {
              this.errorMessage = 'ユーザー名またはパスワードに誤りがあります。'
            }
          }
        // console.log(error)
      })
      }
    }
  }
}
</script>

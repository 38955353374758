import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export function get(repository, params) {
  return new Promise(resolve => {
    // 認証⽤トークンがあればリクエストヘッダに乗せる
    const token = localStorage.getItem('access')
    axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    const payload = axios
      .get(`${repository}/`, params, {
        params:params,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
      })
      .then(response => {
        return { payload: response };
      })
      .catch(error => {
        return { error };
      });
    resolve(payload);
  });
}